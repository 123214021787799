import { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/legacy/image'
import { isAfter } from 'date-fns'
import {
  DISPLAY_BANNER,
  EXPERIMENTS,
  HOME_MESSAGES,
  useFilteredConstants,
} from '@/components/constants'

// Utils
import { getServerSideUserProps } from '@/utils/auth'
import { composeSSR } from '@/utils/ssr'

// Components
import FormMobile from '@/components/search/FormMobile'
import FormMobileNg from '@/components/search/FormMobileNg'
import Head from '@/components/Head'
import TrustpilotScore from '@/components/TrustpilotScore'
import FormDesktop from '@/components/search/FormDesktop'
import Trans from '@/components/i18n/Trans'
import PartnershipModal from '@/components/partnerships/PartnershipModal'
import HomeReassurance from '@/components/HomeReassurance'
import PetitFuteReassurance from '@/components/PetitFuteReassurance'

import CarRentalsHome from '@/components/messages/CarRentalsHome'
import GiftCardHome from '@/components/messages/GiftCardHome'
import AirportEarlyHome from '@/components/messages/AirportEarlyHome'
import DownloadAppHome from '@/components/downloadapp/DownloadAppHome'
import { StrikeFRHome } from '@/components/messages/StrikeMessages'
import BestPrices from '@/components/search/BestPrices'

// Hooks
import useSearch from '@/hooks/search'
import useTranslation from '@/hooks/translation'
import useUser from '@/hooks/user'
import useAirportSearch from '@/v2/location/AirportSearch'
import useBreakpoints from '@/hooks/breakpoints'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import {
  delPartnershipsCookie,
  parsePartnershipsCookie,
  PARTNERSHIPS_COOKIE,
} from '@/utils/cookies'
import * as ServerCookies from 'cookies'

// Assets
import CabineLarge from '@/assets/img/home/cabine-large.jpg'
import viewMobile from '@/assets/img/home/home.png'
import CabineLargeDark from '@/assets/img/home/cabine-large-dark.jpg'
import DefaultContentLayout from '@/layouts/defaultContent'

import api from '@/services/api'
import clsx from 'clsx'
import { useContext } from 'react'
import { ExperimentsContext } from '@/providers/experiments'

function cleanSearchInCache(search) {
  if (!search) return null

  const today = new Date()
  const minSearchDate = new Date()
  minSearchDate.setDate(today.getDate() + 1)

  if (
    isAfter(today, new Date(search.exp)) ||
    isAfter(minSearchDate, new Date(search.slices[0].departure_date))
  ) {
    return null
  }

  search.partnership = null // always delete the partnership
  return search
}

function findAirportByIata(iata, hits) {
  if (hits.length == 0) {
    return null
  }

  return hits.find((item) => item.value == iata) || hits[0]
}

function Home({ partnership, partnershipMode }) {
  const { readItem: readLocalSearch, setItem: saveSearchLocally } =
    useLocalStorage('@ulysse:search')

  const { getExperimentByCode } = useContext(ExperimentsContext)
  const { query, locale } = useRouter()
  const cache = cleanSearchInCache(readLocalSearch())
  const search = useSearch(
    query.origin || query.destination ? null : cache,
    true
  )
  const { user, isFlightClub } = useUser()
  const { t } = useTranslation()
  const searchAirports = useAirportSearch()

  const { isMobile, isTablet } = useBreakpoints()
  const homeMessages = useFilteredConstants().apply(HOME_MESSAGES)

  useEffect(() => {
    search.setPartnership(partnership?.slug)
  }, [partnership?.slug])

  const saveSearch = useCallback(
    (offerRequest) => {
      const exp = new Date()
      exp.setDate(exp.getDate() + 7)
      saveSearchLocally({ ...offerRequest, exp })
    },
    [saveSearchLocally]
  )

  const updateEffect = async () => {
    if (query.origin) {
      const origins = await searchAirports(query.origin)
      const airport = findAirportByIata(query.origin, origins)
      if (airport) search.setOrigin(airport, search.slices[0].id)
    }

    if (query.destination) {
      const destinations = await searchAirports(query.destination)
      const airport = findAirportByIata(query.destination, destinations)
      if (airport) search.setDestination(airport, search.slices[0].id)
    }
  }

  useEffect(() => {
    updateEffect()
  }, [query?.origin, query?.destination])

  const petitfute = partnership?.slug === 'petitfute'
  const newMobileForm = true
  const bestPrices = getExperimentByCode('mobile_best_prices')

  return (
    <>
      <div className="relative">
        <Head
          title={t('pages.index.seoTitle')}
          description={t('pages.index.seoDescription')}
          image="https://storage.googleapis.com/ulysse-www/meta-v4/default.png"
        />

        <header
          className={clsx(
            !newMobileForm &&
              'flex flex-col justify-between h-[70vh] pt-0 md:pt-100',
            newMobileForm &&
              'md:flex md:flex-col justify-between md:min-h-[100vh] xl:min-h-[50vh] md:pt-100',
            'relative',
            {
              'md:pt-160': DISPLAY_BANNER,
              'pt-180': DISPLAY_BANNER && newMobileForm,
              'pt-90': !DISPLAY_BANNER && newMobileForm,
            }
          )}
        >
          <div
            className={clsx(
              newMobileForm ? 'absolute' : 'relative',
              'flex-grow md:h-auto md:absolute top-0 left-0 right-0 bottom-0'
            )}
          >
            <Image
              src={
                isMobile || isTablet
                  ? viewMobile
                  : isFlightClub
                  ? CabineLargeDark
                  : CabineLarge
              }
              layout="fill"
              objectFit="cover"
              objectPosition={isMobile || isTablet ? 'center' : 'top'}
              quality={100}
              priority
              placeholder="blur"
              alt=""
            />
            <div
              className={clsx(
                'md:hidden absolute bottom-0 left-0 w-full bg-gradient-to-b from-transparent to-white',
                newMobileForm ? 'h-[300px]' : 'h-100'
              )}
            />
          </div>
          <div className="hidden md:block absolute bottom-0 left-0 w-full h-180 bg-gradient-to-b from-transparent to-dark-20" />
          <div className="block md:hidden absolute top-0 left-0 w-full h-180 bg-gradient-to-b from-dark-20 to-transparent" />
          <section className="relative">
            <div className={`pb-15 pt-10 md:pt-0 md:pb-20`}>
              <div className="container">
                <h1
                  className={clsx(
                    'leading-none font-black',
                    newMobileForm
                      ? 'text-[37px] text-shadow text-white'
                      : 'text-50 text-shadow-light md:text-shadow md:text-white',
                    'md:text-50'
                  )}
                >
                  <Trans
                    i18nKey={
                      user?.first_name
                        ? 'pages.index.loggedInTitle'
                        : user
                        ? 'pages.index.loggedOutTitle'
                        : 'pages.index.loggedOutTitle'
                    }
                    values={{
                      name: user?.first_name,
                    }}
                    components={{ br: <br /> }}
                  />
                </h1>
                <p
                  className={clsx(
                    newMobileForm
                      ? 'text-white md:mt-10'
                      : 'text-dark-60 mt-10',
                    'text-18 md:text-22 md:text-shadow md:text-white font-bold'
                  )}
                >
                  {user
                    ? t('pages.index.loggedInSubtitle')
                    : t('pages.index.loggedOutSubtitle')}
                </p>
              </div>
            </div>
            <div
              className={clsx(
                !newMobileForm && 'bg-white',
                'md:bg-transparent md:pb-0 pb-30'
              )}
            >
              <section className="container">
                <div className="md:hidden">
                  {newMobileForm ? (
                    <FormMobileNg search={search} onCreate={saveSearch} />
                  ) : (
                    <FormMobile search={search} onCreate={saveSearch} />
                  )}
                </div>
                <div className="hidden md:block">
                  <FormDesktop
                    search={search}
                    hideHomeSmartFilters={false}
                    onCreate={saveSearch}
                  />
                </div>
              </section>
            </div>
            {homeMessages?.length > 0 && (
              <div className="bg-transparent">
                <div className="container grid grid-flow-col overflow-auto lg:flex items-stretch gap-20 pb-20 md:mt-30 z-0">
                  {homeMessages.map((e) => {
                    if (e === 'giftcard') {
                      return (
                        <GiftCardHome
                          key="giftCardHome"
                          isLight={isFlightClub}
                        />
                      )
                    }
                    if (e === 'airport') {
                      return (
                        <AirportEarlyHome
                          key="airportEarlyHome"
                          isLight={isFlightClub}
                        />
                      )
                    }
                    if (e === 'downloadapp') {
                      return (
                        <DownloadAppHome
                          key="downloadAppHome"
                          isLight={isFlightClub}
                        />
                      )
                    }
                    if (e === 'strike') {
                      return (
                        <StrikeFRHome
                          key="strikeFrHome"
                          isLight={isFlightClub}
                        />
                      )
                    }
                    if (e === 'carrentals') {
                      return (
                        <CarRentalsHome key="carRentalsHome" isLight={false} />
                      )
                    }
                    return null
                  })}
                </div>
              </div>
            )}
          </section>
          <section
            className={clsx(
              'bg-white md:bg-transparent pb-20 relative',
              newMobileForm && 'hidden md:block'
            )}
          >
            <div className="container flex justify-between">
              <TrustpilotScore
                size="lg"
                className="text-white hidden md:flex"
              />
              <TrustpilotScore className="flex md:hidden" />
            </div>
          </section>
        </header>
      </div>
      {newMobileForm && (
        <section className="md:hidden bg-white md:bg-transparent relative">
          <div className="container flex justify-between">
            <TrustpilotScore size="lg" className="text-white hidden md:flex" />
            <TrustpilotScore className="flex md:hidden" />
          </div>
        </section>
      )}
      {locale === 'fr' && bestPrices == 'b' && (
        <section className="md:hidden bg-white md:bg-transparent relative">
          <BestPrices />
        </section>
      )}
      {!user && locale === 'fr' && petitfute && <PetitFuteReassurance />}
      {!user && locale === 'fr' && !petitfute && <HomeReassurance />}
      {partnership &&
      [partnershipMode, 'both'].includes(partnership.show_popins) ? (
        <PartnershipModal
          partnership={partnership}
          mode={partnershipMode}
          deletePartnership={() => {
            delPartnershipsCookie()
            search.setPartnership(null)
          }}
        />
      ) : null}
    </>
  )
}

Home.layout = DefaultContentLayout
export default Home
export const getServerSideProps = composeSSR(
  getServerSideUserProps,
  // Fetch partnership
  async (context) => {
    const cookies = new ServerCookies(context.req, context.res)
    const partnerFromQuery = context.query && context.query.partnerId
    const partnerFromCookie = parsePartnershipsCookie(
      cookies.get(PARTNERSHIPS_COOKIE)
    )?.name
    const partnerId = partnerFromQuery || partnerFromCookie

    const experiments = {
      new_mobile_form: EXPERIMENTS['new_mobile_form'].get(context),
      mobile_best_prices: EXPERIMENTS['mobile_best_prices'].get(context),
    }

    if (partnerId) {
      const res = await api.partnerships.getWithoutFailing(partnerId)
      if (res?.data) {
        return {
          props: {
            experiments,
            partnership: res.data,
            partnershipMode:
              partnerId == partnerFromQuery ? 'welcome' : 'returning',
          },
        }
      }
    }

    return {
      props: { experiments },
    }
  }
)
