import clsx from 'clsx'
import Info from '@/assets/svg/circle-info.svg'
import Link from 'next/link'

const title = 'Mouvement de grève le 19/01/2023'
const message = (
  <>
    Suite à l&#39;annonce de la grève nationale du 19 janvier, les transports
    depuis et vers les aéroports en France seront perturbés. Retrouvez plus
    d&#39;information{' '}
    <Link href="/travelnews/467" className="underline" alt="travel news">
      ici
    </Link>
    .
  </>
)

/**
 * Strike message only for FR
 */
export function StrikeFRHome({ isLight }) {
  return (
    <div className="flex min-w-[300px] md:min-w-auto md:max-w-[300px] lg:max-w-auto bg-white md:bg-transparent">
      <div
        className={clsx(
          'group rounded py-15 px-20 md:text-white flex flex-col justify-between shadow-md',
          isLight ? 'md:bg-white-20' : 'md:bg-dark-30',
          'hover:shadow-lg hover:-translate-y-2 motion-safe:transition motion-safe:duration-300 ease-out'
        )}
      >
        <div>
          <p className="opacity-80 text-14">{title}</p>
          <p className="font-bold text-14 mt-5">{message}</p>
        </div>
      </div>
    </div>
  )
}

export function StrikeResultBanner() {
  return (
    <>
      <div className="w-full bg-warning-light rounded-5 flex border-dark-10 border p-15 items-center gap-15">
        <span>
          <Info color="#743A05" />
        </span>
        <div className="text-14">{message}</div>
      </div>
    </>
  )
}
