import useTranslation from '@/hooks/translation'
import useAnalytics from '@/hooks/analytics'

import Link from 'next/link'
import Image from 'next/legacy/image'

import MobileDevices from '@/assets/img/downloadapp/mobile-phones.png'
import Arrow from '@/assets/svg/arrow.svg'
import clsx from 'clsx'
import useMobileOperator from '@/hooks/mobile-operator'

import { STORE_ANDROID_URL, STORE_IOS_URL } from '../constants'

export default function DownloadAppHome({ isLight }) {
  const { t } = useTranslation()
  const { track } = useAnalytics()
  const os = useMobileOperator()
  const url = os == 'Android' ? STORE_ANDROID_URL : STORE_IOS_URL

  return (
    (os == 'Android' || os == 'iOS') && (
      <div className="flex min-w-[300px] md:min-w-auto md:max-w-[300px] lg:max-w-auto bg-white md:bg-transparent">
        <Link
          href={url}
          target="_blank"
          onClick={() =>
            track('website_download_mobileapp_clicked', {
              where: 'home_promote',
              mobileOs: os,
            })
          }
          rel="noopener noreferrer"
          className={clsx(
            'relative group rounded md:text-white shadow-md w-full',
            isLight ? 'md:bg-white-20' : 'md:bg-dark-30',
            'hover:shadow-lg hover:-translate-y-2 motion-safe:transition motion-safe:duration-300 ease-out'
          )}
        >
          <div className="relative py-15 px-20 w-10/12 z-[1]">
            <p className="opacity-80 text-14">
              {t('components.downloadapp.banner.title')}
            </p>
            <p className="font-bold text-14 mt-5">
              {t('components.downloadapp.panel.subtitle')}
            </p>
            <p className="font-bold flex items-center text-14 mt-15">
              {t('components.downloadapp.banner.link')}
              <Arrow className="group-hover:translate-x-5 transform fill-white ml-5 transition duration-300 ease-out" />
            </p>
          </div>
          <div className="absolute bottom-0 right-0 top-[50%] left-[50%] z-0">
            <Image
              src={MobileDevices}
              layout="fill"
              objectFit="contain"
              objectPosition="bottom right"
              alt="Mobile devices"
            />
          </div>
        </Link>
      </div>
    )
  )
}
