import useAnalytics from '@/hooks/analytics'

import Link from 'next/link'

import Arrow from '@/assets/svg/arrow.svg'
import clsx from 'clsx'

export default function CarRentalsHome({ isLight }) {
  const { track } = useAnalytics()

  return (
    <div className="flex mt-20 md:mt-0 min-w-[320px] md:min-w-auto md:max-w-[420px] lg:max-w-auto bg-white md:bg-transparent">
      <Link
        href="/cars/search"
        onClick={() => track('cars_clicked_homepage')}
        className={clsx(
          'relative group rounded md:text-white shadow-md w-full',
          isLight ? 'md:bg-white-20' : 'md:bg-dark-30',
          'hover:shadow-lg motion-safe:transition motion-safe:duration-300 ease-out'
        )}
      >
        <div className="relative py-15 px-20 z-[1]">
          <p className="opacity-80 text-14">
            Et on loue aussi des voitures !{' '}
            <span className="inline-block ml-3">🚗</span>
          </p>
          <p className="font-bold text-14 mt-5">
            On a sélectionné, pour vous, les meilleurs loueurs et votre
            réservation est annulable gratuitement 48h à l’avance !
          </p>
          <p className="font-bold flex items-center text-14 mt-15">
            Roulez jeunesse
            <Arrow className="group-hover:translate-x-5 transform fill-white ml-5 mt-2 transition duration-300 ease-out" />
          </p>
        </div>
      </Link>
    </div>
  )
}
